import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import cn from "./cn.json";
import de from "./de.json";
import es from "./es.json";
import jp from "./jp.json";

const resources = {
  en,
  cn,
  de,
  es,
  jp,
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  lng: localStorage.getItem("language") ?? "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
