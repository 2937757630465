import React, { Component } from "react";
import { Icon, Layout, DatePicker, AutoComplete, Tooltip } from "antd";
import { DocumentTypes, Commodities, SigningEntity } from "../utils/Constants";
import { polyfill } from "es6-promise";
import { withTranslation } from "react-i18next";

import "antd/dist/antd.css";

const { Sider } = Layout;
const { RangePicker } = DatePicker;

polyfill();

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentTypeInput: this.props.selectedDocumentType,
      commodityInput: this.props.selectedCommodity,
      signingEntity: this.props.selectedSigningEntity,
    };
  }

  render = () => {
    console.log("Doc Type = ", Object.keys(DocumentTypes))
    return (
      <Sider
        theme="light"
        hidden={!this.props.isFilterOpen}
        width={300}
        style={{
          position: "fixed",
          padding: 20,
          height: "92vh",
          backgroundColor: "#FFF",
          boxShadow: "0 0 8px 0 #E9E9E9",
        }}
      >
        <div
          style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <Tooltip title={this.props.t("filterRestTp")}>
            <Icon
              style={{ color: "#1890FF", fontSize: 20, fontWeight: "bold" }}
              type="undo"
              onClick={() =>
                this.setState(
                  {
                    documentTypeInput: "",
                    commodityInput: "",
                    signingEntity: "",
                  },
                  () => this.props.onReset()
                )
              }
            />
          </Tooltip>
        </div>

        <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}>
          <Icon type="paper-clip" /> {this.props.t("docType")}
        </p>
        <AutoComplete
          style={{ width: "100%" }}
          size="large"
          dataSource={Object.keys(DocumentTypes)}
          placeholder={this.props.t("selectDocType")}
          value={this.state.documentTypeInput}
          allowClear={true}
          onBlur={(value) => {
            if (DocumentTypes[value] === undefined) {
              this.setState({ documentTypeInput: "" });
              this.props.onDocumentTypeSelect(null);
            }
          }}
          onChange={(value) => this.setState({ documentTypeInput: value })}
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={(value) => this.props.onDocumentTypeSelect(value)}
        />

        <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}>
          <Icon type="container" /> {this.props.t("mg")}
        </p>
        <AutoComplete
          style={{ width: "100%" }}
          size="large"
          dataSource={Object.keys(Commodities)}
          placeholder={this.props.t("selectMg")}
          value={this.state.commodityInput}
          allowClear={true}
          onBlur={(value) => {
            if (Commodities[value] === undefined) {
              this.setState({ commodityInput: "" });
              this.props.onCommoditySelect(null);
            }
          }}
          onChange={(value) => this.setState({ commodityInput: value })}
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={(value) => this.props.onCommoditySelect(value)}
        />

        <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}>
          <Icon type="container" /> {this.props.t("signEntity")}
        </p>
        <AutoComplete
          style={{ width: "100%" }}
          size="large"
          dataSource={Object.keys(SigningEntity).sort()}
          placeholder={this.props.t("selectSignEntity")}
          value={this.state.signingEntity}
          allowClear={true}
          onBlur={(value) => {
            if (SigningEntity[value] === undefined) {
              this.setState({ signingEntity: "" });
              this.props.onSigningEntitySelect(null);
            }
          }}
          onChange={(value) => this.setState({ signingEntity: value })}
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={(value) => this.props.onSigningEntitySelect(value)}
        />

        {/* <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}><Icon type='calendar' /> Effective Date Range</p>
            <RangePicker size='large' value={[this.props.effectiveDateRange.start, this.props.effectiveDateRange.end]} onChange={this.props.onEffectiveDateChange} /> */}

        <p style={{ fontSize: 14, marginTop: 16, marginBottom: 12 }}>
          <Icon type="calendar" /> {this.props.t("expDateRange")}
        </p>
        <RangePicker
          size="large"
          placeholder={[this.props.t("startDate"), this.props.t("endDate")]}
          value={[
            this.props.expirationDateRange.start,
            this.props.expirationDateRange.end,
          ]}
          onChange={this.props.onExpirationDateChange}
        />
      </Sider>
    );
  };
}

export default withTranslation()(Filter);
