import React, { Component } from "react";
import { Layout, message, Spin, Modal, Descriptions, Card, Table } from "antd";
import { polyfill } from "es6-promise";
import * as Request from "request";

import "antd/dist/antd.css";

import * as Auth from "../utils/Auth";
import * as Constant from "../utils/Constants";
import PageHeader from "./PageHeader";
import Filter from "./Filter";
import Contracts from "./Contracts";
import Supplier from "./Supplier";
import { withTranslation } from "react-i18next";

const { Content } = Layout;

polyfill();

const supplierColumns = (t) => [
  {
    title: t("purchaseOrgMTbl"),
    dataIndex: "ekorg",
    key: "ekorg",
  },
  {
    title: t("termsOfPaymentKeyMTbl"),
    dataIndex: "zterm",
    key: "zterm",
  },
  {
    title: t("incoterms1MTbl"),
    dataIndex: "inco1",
    key: "inco1",
  },
  {
    title: t("incoterms2MTbl"),
    dataIndex: "inco2",
    key: "inco2",
  },
  {
    title: t("currencyMTbl"),
    dataIndex: "waers",
    key: "waers",
  },
  {
    title: t("puchasingBlockMTbl"),
    dataIndex: "sperm",
    key: "sperm",
  },
];

const spendColumns = (t) => [
  {
    title: t("yearMTbl"),
    dataIndex: "year",
    key: "year",
  },
  {
    title: t("spendUsdMTbl"),
    dataIndex: "spend",
    key: "spend",
  },
];
const spendExpanded = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "MATL Group",
    dataIndex: "matl",
    key: "matl",
  },
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    render: (spend) => parseInt(spend).toLocaleString(),
  },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterOpen: true,
      keywords: [],
      searchText: "",

      selectedDocumentType: null,
      selectedContractStatus: null,
      effectiveDateRange: {
        start: null,
        end: null,
      },
      expirationDateRange: {
        start: null,
        end: null,
      },
      selectedCommodity: null,
      selectedSigningEntity: null,

      inResetState: true,
      loading: false,
      contracts: [],
      selectedRows: [],

      paginationId: null,

      showingSupplier: false,
      loadingSupplier: false,
      loadingSpend: false,
      supplierId: null,
      supplierData: [],
      spendData: [],
      spendAggregatedData: [],
      page: 1,
      visible: false,
      downloads: {
        visible: false,
        contracts: [],
      },
    };
  }

  componentDidMount = () => {
    if (Auth.isAuthenticated() === false)
      window.location = Auth.getAuthUrl("/");
  };

  render = () => {
    return Auth.isAuthenticated() === true ? (
      <Layout>
        <PageHeader
          isFilterOpen={this.state.isFilterOpen}
          toggleFilter={this.toggleFilter}
          keywords={this.state.keywords}
          searchText={this.state.searchText}
          onSearchTextChange={this.onSearchTextChange}
          handleInputConfirm={this.handleInputConfirm}
          onRemoveKeyword={this.onRemoveKeyword}
          showingSupplier={this.state.showingSupplier}
          contractsLength={
            this.state.loading === true ? 0 : this.state.contracts.length
          }
          downloads={this.state.downloads}
        />

        {this.getLayoutView()}
      </Layout>
    ) : (
      <div className="fullscreen-loader">
        <Spin />
      </div>
    );
  };

  getLayoutView = () => {
    if (
      this.state.showingSupplier === true &&
      this.state.loadingSupplier === true
    )
      return (
        <div className="fullscreen-loader">
          <Spin />
        </div>
      );
    else if (this.state.showingSupplier === true)
      return (
        <Supplier
          supplierData={this.state.supplierData}
          spendData={this.state.spendData}
          spendAggregatedData={this.state.spendAggregatedData}
          loadingSpend={this.state.loadingSpend}
        />
      );
    else
      return (
        <Content className="container">
          <Layout style={{ backgroundColor: "#FFF" }}>
            <Filter
              isFilterOpen={this.state.isFilterOpen}
              selectedDocumentType={this.state.selectedDocumentType}
              onDocumentTypeSelect={this.onDocumentTypeSelect}
              selectedContractStatus={this.state.selectedContractStatus}
              onContractStatusSelect={this.onContractStatusSelect}
              effectiveDateRange={this.state.effectiveDateRange}
              expirationDateRange={this.state.expirationDateRange}
              onEffectiveDateChange={this.onEffectiveDateChange}
              onExpirationDateChange={this.onExpirationDateChange}
              selectedCommodity={this.state.selectedCommodity}
              onCommoditySelect={this.onCommoditySelect}
              selectedSigningEntity={this.state.selectedSigningEntity}
              onSigningEntitySelect={this.onSigningEntitySelect}
              onApplyFilter={this.onApplyFilter}
              onReset={this.onReset}
            />
            <Contracts
              margin={this.state.isFilterOpen === true ? 300 : 0}
              inResetState={this.state.inResetState}
              loading={this.state.loading}
              contracts={this.state.contracts}
              selectedRows={this.state.selectedRows}
              onContractSelect={this.onContractSelect}
              onSupplierClick={this.onSupplierClick}
              page={this.state.page}
              onPageChange={this.onPageChange}
            />
            <div
              hidden={this.state.loadingSupplier === false}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100vw",
                height: "100vh",
                display: "flex",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <Spin />
            </div>
            <Modal
              okButtonProps={{ hidden: true }}
              className="custom_modal"
              style={{ display: "flex", top: 20 }}
              visible={this.state.visible}
              cancelButtonProps={{ hidden: true }}
              onOk={() => this.setState({ visible: false })}
              onCancel={() => this.setState({ visible: false })}
            >
              {this.state.supplierData.length !== 0 && (
                <div>
                  <div
                    style={{
                      paddingRight: 50,
                      paddingBottom: 50,
                      paddingLeft: 50,
                      backgroundColor: "#FFF",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1px #E8E8E8 solid",
                        padding: 20,
                        marginTop: 30,
                        borderRadius: 5,
                        backgroundColor: "#FFF",
                      }}
                    >
                      <Card
                        className="supplyinfo"
                        size="small"
                        title={
                          this.state.supplierData[0].lifnr +
                          " - " +
                          this.state.supplierData[0].name1
                        }
                        bordered
                        style={{ marginRight: 20, width: "auto" }}
                      >
                        <p>{this.state.supplierData[0].stras}</p>
                        <p>
                          {this.createAddressString(this.state.supplierData[0])}
                        </p>
                      </Card>

                      <Descriptions
                        className="supplier-info"
                        style={{ marginRight: 20 }}
                      >
                        <Descriptions.Item
                          span={1}
                          label={this.props.t("accountGroupMH")}
                        >
                          {this.state.supplierData[0].ktokk}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={this.props.t("annualSpendRankMH")}
                        >
                          {this.state.supplierData[0].zzvnd_sprnk}
                        </Descriptions.Item>
                        {/* <Descriptions.Item span={2} label="Approval Ratings ">
                          {this.state.supplierData[0].zsupar}
                        </Descriptions.Item> */}
                        <Descriptions.Item
                          span={1}
                          label={this.props.t("organizationStructureMH")}
                        >
                          {this.state.supplierData[0].zorg_tx}
                        </Descriptions.Item>
                        {/* <Descriptions.Item
                          span={2}
                          label="Supplier Approval Class"
                        >
                          {this.state.supplierData[0].zsupac}
                        </Descriptions.Item> */}
                        <Descriptions.Item
                          span={1}
                          label={this.props.t("vendorTypeMH")}
                        >
                          {this.state.supplierData[0].zzvnd_vtyp}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div
                        style={{ flex: 1, paddingTop: 20, paddingRight: 10 }}
                      >
                        <Table
                          loading={this.state.loadingSpend}
                          columns={spendColumns(this.props.t)}
                          dataSource={this.state.spendAggregatedData}
                          expandedRowRender={this.spendDataTable}
                          pagination={false}
                          bordered
                        />
                      </div>

                      <div style={{ flex: 2, paddingTop: 20, paddingLeft: 10 }}>
                        <Table
                          columns={supplierColumns(this.props.t)}
                          dataSource={this.state.supplierData}
                          pagination={false}
                          bordered
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </Layout>
        </Content>
      );
  };

  createAddressString = (data) => {
    let address = "";
    if (data.ort01) address += data.ort01 + ", ";
    if (data.ort02) address += data.ort02 + ", ";
    if (data.regio) address += data.regio + " ";
    if (data.pstlz) address += data.pstlz + " ";
    if (data.land1) address += data.land1;
    return address;
  };

  spendDataTable = (option) => {
    return (
      <Table
        columns={spendExpanded}
        dataSource={this.state.spendData.filter(
          (value) => value.year === option.year
        )}
        pagination={false}
      />
    );
  };

  // PageHeader interaction methods.
  toggleFilter = () => {
    const { isFilterOpen, showingSupplier } = this.state;
    if (showingSupplier === true)
      this.setState({ showingSupplier: false, loadingSupplier: false });
    else this.setState({ isFilterOpen: !isFilterOpen });
  };
  onSearchTextChange = (searchText) => {
    this.setState({ searchText });
  };
  handleInputConfirm = () => {
    let { searchText, keywords } = this.state;
    if (searchText && keywords.indexOf(searchText) === -1) {
      keywords = [...keywords, searchText];
      this.applyFilter(keywords);
    }
    this.setState({ searchText: "", keywords });
  };
  onRemoveKeyword = (removedKeyword) => {
    let { keywords } = this.state;
    keywords = keywords.filter((keyword) => keyword !== removedKeyword);
    this.setState({ keywords }, () => this.applyFilter(keywords));
  };
  // Contracts interactions methods.
  onContractSelect = (selectedRows) => {
    this.setState({ selectedRows });
    let visible = false,
      contracts = [];
    if (selectedRows.length) {
      contracts = this.state.contracts.filter((contract) =>
        selectedRows.includes(contract.contract_id)
      );
      visible = true;
    }
    this.setState({
      downloads: {
        visible,
        contracts,
      },
    });
  };
  onSupplierClick = (supplierId) => {
    this.setState(
      {
        // showingSupplier: true,
        loadingSupplier: true,
        supplierId,
      },
      () => this.getSupplierData()
    );
  };
  onPageChange = (page) => {
    this.setState({ page });
  };
  // Filter interaction methods.
  onDocumentTypeSelect = (selectedDocumentType) => {
    if (selectedDocumentType === this.state.selectedDocumentType) return;
    this.setState({ selectedDocumentType }, () => this.onApplyFilter());
  };
  onContractStatusSelect = (selectedContractStatus) => {
    this.setState({ selectedContractStatus });
  };
  onCommoditySelect = (selectedCommodity) => {
    if (selectedCommodity === this.state.selectedCommodity) return;
    this.setState({ selectedCommodity }, () => this.onApplyFilter());
  };
  onSigningEntitySelect = (selectedSigningEntity) => {
    if (selectedSigningEntity === this.state.selectedSigningEntity) return;
    this.setState({ selectedSigningEntity }, () => this.onApplyFilter());
  };
  onEffectiveDateChange = (range) => {
    if (range.length === 0)
      this.setState(
        {
          effectiveDateRange: {
            start: null,
            end: null,
          },
        },
        () => this.applyFilter(this.state.keywords)
      );
    else if (range.length === 2)
      this.setState(
        {
          effectiveDateRange: {
            start: range[0],
            end: range[1],
          },
        },
        () => this.applyFilter(this.state.keywords)
      );
  };
  onExpirationDateChange = (range) => {
    if (range.length === 0)
      this.setState(
        {
          expirationDateRange: {
            start: null,
            end: null,
          },
        },
        () => this.applyFilter(this.state.keywords)
      );
    else if (range.length === 2)
      this.setState(
        {
          expirationDateRange: {
            start: range[0],
            end: range[1],
          },
        },
        () => this.applyFilter(this.state.keywords)
      );
  };
  onReset = () => {
    this.setState(
      {
        selectedSigningEntity: null,
        selectedDocumentType: null,
        selectedContractStatus: null,
        effectiveDateRange: {
          start: null,
          end: null,
        },
        expirationDateRange: {
          start: null,
          end: null,
        },
        selectedCommodity: null,
        keywords: [],
        inResetState: true,
        loading: false,
        contracts: [],
        selectedRows: [],
      },
      () => message.info("Filter reset")
    );
  };
  onApplyFilter = () => {
    this.applyFilter(this.state.keywords);
  };
  // Make API call and fetch result.
  validateFilters = () => {
    if (this.state.selectedDocumentType != null) return true;
    if (this.state.selectedContractStatus != null) return true;
    if (
      this.state.effectiveDateRange &&
      this.state.effectiveDateRange.start &&
      this.state.effectiveDateRange.end
    )
      return true;
    if (
      this.state.expirationDateRange &&
      this.state.expirationDateRange.start &&
      this.state.expirationDateRange.end
    )
      return true;
    if (this.state.selectedCommodity !== null) return true;
    if (this.state.selectedSigningEntity !== null) return true;
    return false;
    // selectedDateRange
  };
  applyFilter = async (keywords) => {
    if (keywords.length === 0 && this.validateFilters() === false) {
      this.setState({ loading: false, inResetState: true, contracts: [] });
      return;
    }
    // Create request object.
    const body = {
      keywords: keywords,
      document_type_code: this.state.selectedDocumentType
        ? Constant.DocumentTypes[this.state.selectedDocumentType]
        : null,
      contract_status: this.state.selectedContractStatus
        ? this.state.selectedContractStatus
        : null,
      effective_date_range: {
        start:
          this.state.effectiveDateRange.start === null
            ? null
            : this.state.effectiveDateRange.start
                .format("YYYY-MM-DD")
                .toString(),
        end:
          this.state.effectiveDateRange.end === null
            ? null
            : this.state.effectiveDateRange.end.format("YYYY-MM-DD").toString(),
      },
      expiration_date_range: {
        start:
          this.state.expirationDateRange.start === null
            ? null
            : this.state.expirationDateRange.start
                .format("YYYY-MM-DD")
                .toString(),
        end:
          this.state.expirationDateRange.end === null
            ? null
            : this.state.expirationDateRange.end
                .format("YYYY-MM-DD")
                .toString(),
      },
      commodity: this.state.selectedCommodity
        ? Constant.Commodities[this.state.selectedCommodity]
        : null,
      signing_entity: this.state.selectedSigningEntity
        ? this.state.selectedSigningEntity.replace(",", "").toUpperCase()
        : null,
    };

    this.setState({ loading: true, inResetState: false });
    Request(
      process.env.REACT_APP_BASE_URL + "/queryContracts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Auth.getAuthToken(),
        },
        body: JSON.stringify(body),
      },
      (error, response) => {
        if (this.state.inResetState === true) return; // User must've pressed reset during loading data.
        if (error) {
          message.error("Couldn't get results!");
          this.setState({ loading: false, inResetState: true });
          return;
        }

        const object = JSON.parse(response.body);
        this.setState({
          loading: false,
          contracts: object.contracts ? object.contracts : [],
          isFilterOpen: false,
        });
      }
    );
  };
  getSupplierData = async () => {
    const body = { supplier_id: this.state.supplierId };

    Request(
      process.env.REACT_APP_BASE_URL + "/querySuppliers",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: Auth.getAuthToken(),
        },
        body: JSON.stringify(body),
      },
      (error, response) => {
        if (error) {
          message.error("Couldn't get supplier info!");
          this.setState({ showingSupplier: false, loadingSupplier: false });
          return;
        }

        const object = JSON.parse(response.body);
        this.setState(
          {
            supplierData: object.suppliers,
            visible: true,
            loadingSupplier: false,
            loadingSpend: true,
          },
          () => this.getSpendData()
        );
      }
    );
  };
  getSpendData = async () => {
    const body = { supplier_id: this.state.supplierId };
    Request(
      process.env.REACT_APP_BASE_URL + "/querySpendData",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: Auth.getAuthToken(),
        },
        body: JSON.stringify(body),
      },
      (error, response) => {
        if (error) {
          this.setState({ loadingSpend: false }, () =>
            message.error("Couldn't fetch spend.")
          );
          return;
        }

        const object = JSON.parse(response.body);

        const aggregatedObject = {};
        if (object.spend) {
          object.spend.forEach((spend) => {
            let aggregatedSpend = aggregatedObject[spend.year];
            if (aggregatedSpend) {
              aggregatedObject[spend.year] =
                aggregatedSpend + parseInt(spend.spend);
            } else {
              aggregatedObject[spend.year] = parseInt(spend.spend);
            }
          });
        }
        const yearSpend = Object.keys(aggregatedObject)
          .map((value) => {
            return {
              year: value,
              spend: aggregatedObject[value].toLocaleString(),
            };
          })
          .sort((a, b) => b.year - a.year);

        this.setState({
          spendData: object.spend,
          spendAggregatedData: yearSpend,
          loadingSpend: false,
        });
      }
    );
  };
}
export default withTranslation()(Home);
