import React from "react";
import { Layout, Table } from "antd";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const columns = (t, onSupplierClick) => {
  return [
    {
      title: t("contractID"),
      dataIndex: "contract_id",
      key: "contract_id",
      width: 110,
      render: (contract_id) => (
        <a
          href={
            "https://molex.sourcing.ariba.com/ad/viewDocument?ID=" + contract_id
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {contract_id}
        </a>
      ),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      width: 400,
    },
    {
      title: t("mg"),
      dataIndex: "commodity_name",
      key: "commodity_name",
      // width: 250,
      render: (commodity, object) => {
        if (object.commodity_id === "" || object.commodity_id === " ")
          return commodity;
        else if (commodity === "" || commodity === " ")
          return object.commodity_id;
        else return `${object.commodity_id} - ${commodity}`;
      },
      // sorter: (a, b) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: t("supplierName"),
      dataIndex: "supplier_name",
      key: "supplier_name",
      // sorter: true,
      // width: 250,
      render: (supplier, object) => {
        if (
          (supplier === "" && object.supplier_id === "") ||
          (supplier === " " && object.supplier_id === " ") ||
          (supplier === " " && object.supplier_id === "") ||
          (supplier === "" && object.supplier_id === " ")
        )
          return "";
        else if (
          (object.supplier_id === "" && supplier !== "") ||
          (object.supplier_id === " " && supplier !== "")
        )
          return supplier;
        else
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={(event) => onSupplierClick(object.supplier_id)}>
              {object.supplier_id + " - " + supplier}
            </a>
          );
      },
    },
    {
      title: t("docType"),
      dataIndex: "document_type",
      key: "document_type",
      // sorter: true,
      // width: 200
      // render: (document_type, object) => {
      // 	if (object.document_type.localeCompare('Non Disclosure Agreement (NDA)')) {
      // 		return object.document_type
      // 	} else {
      // 		object.status = ''
      // 		object.term_type = ''
      // 		return object.document_type
      // 	}
      // },
    },
    {
      title: t("signEntity"),
      dataIndex: "signing_entity",
      key: "signing_entity",
      // sorter: true
      // width: 100
    },
    {
      title: t("effectiveDate"),
      dataIndex: "effective_date",
      key: "effective_date",
      width: 120,
      // sorter: true
    },
    {
      title: t("expirationDate"),
      dataIndex: "expiration_date",
      key: "expiration_date",
      width: 120,
      // sorter: true
    },
    {
      title: t("contractStatus"),
      dataIndex: "status",
      key: "status",
      render: (status, object) => `${status} - ${object.term_type}`,
      // sorter: true
      // width: 100
    },

    {
      title: t("paymentTerm"),
      dataIndex: "payment_term_text",
      key: "payment_term_text",
      width: 120,
    },
    {
      title: t("inco"),
      dataIndex: "inco_term_text",
      key: "inco_term_text",
      width: 100,
    },
    // {
    // 	title: 'Contract Amount',
    // 	dataIndex: 'amount',
    // 	key: 'amount',
    // 	render: amount => (amount !== '' || amount !== ' ') && parseInt(amount).toLocaleString(),
    // 	// sorter: true,
    // 	// width: 200
    // },
    // {
    // 	title: 'Currency',
    // 	dataIndex: 'currency',
    // 	key: 'currency',
    // 	// sorter: true,
    // 	// width: 200
    // },
  ];
};

const ContractsTable = ({
  contracts,
  selectedRows,
  onContractSelect,
  onSupplierClick,
  page,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const rowSelection = {
    selectedRows,
    onChange: onContractSelect,
  };

  return (
    <Content className="supplier-table">
      <Table
        size="small"
        rowKey={(object) => object.contract_id}
        columns={columns(t, onSupplierClick)}
        dataSource={contracts}
        scroll={{ x: 2300 }}
        rowSelection={rowSelection}
        bordered
      />
    </Content>
  );
};

export default ContractsTable;
