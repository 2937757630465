import React from "react";
import {
  Layout,
  Icon,
  Tag,
  Input,
  Tooltip,
  Button,
  Dropdown,
  Menu,
} from "antd";
import ReactExport from "react-export-excel";
import { withTranslation, useTranslation } from "react-i18next";

import "../App.css";
import "antd/dist/antd.css";

import logo from "../assets/Molex_logo_white.png";

const { Header } = Layout;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const DownloadCsv = ({ t, downloads }) => {
  return (
    <ExcelFile
      element={
        <Tooltip title={t("excelDownloadTp")}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#E30536",
              color: "white",
              border: "none",
              marginRight: "10px",
              boxShadow: "none",
            }}
            size="large"
            icon="download"
          />
        </Tooltip>
      }
    >
      <ExcelSheet data={downloads.contracts} name="supplier_contract_database">
        {Object.keys(downloads.contracts[0]).map((key) => (
          <ExcelColumn key={`${key}`} label={key} value={key} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

const LanguageMenu = ({ t }) => {
  const { i18n } = useTranslation();

  const onSelect = ({ key }) => {
    localStorage.setItem("language", key);
    i18n.changeLanguage(key);
  };

  return (
    <Menu onSelect={onSelect}>
      <Menu.Item key={"en"}>{t("en")}</Menu.Item>
      <Menu.Item key={"cn"}>{t("cn")}</Menu.Item>
      <Menu.Item key={"jp"}>{t("jp")}</Menu.Item>
      <Menu.Item key={"de"}>{t("de")}</Menu.Item>
      <Menu.Item key={"es"}>{t("es")}</Menu.Item>
    </Menu>
  );
};

const PageHeader = ({
  isFilterOpen,
  showingSupplier,
  toggleFilter,
  keywords,
  searchText,
  onSearchTextChange,
  handleInputConfirm,
  onRemoveKeyword,
  contractsLength,
  downloads,
  t,
}) => {
  const { i18n } = useTranslation();

  return (
    <Header className="header">
      {showingSupplier === true ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Back">
            <Icon
              type="arrow-left"
              onClick={() => toggleFilter()}
              style={{ fontSize: 22, paddingRight: 20 }}
            />
          </Tooltip>
          <img src={logo} height="30" alt="Molex logo" />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={
                isFilterOpen === true
                  ? t("filterPaneTpShow")
                  : t("filterPaneTpHide")
              }
            >
              <Icon
                type={isFilterOpen === true ? "menu-fold" : "menu-unfold"}
                onClick={() => toggleFilter()}
                style={{ fontSize: 22, paddingRight: 20 }}
              />
            </Tooltip>
            <img src={logo} height="30" alt="Molex logo" />
            <div
              style={{
                height: 30,
                width: 1,
                marginLeft: 80,
                backgroundColor: "#FFF",
              }}
            ></div>
            <Input
              style={{ marginLeft: 10, minWidth: 300, width: 300 }}
              placeholder={t("search")}
              size="default"
              value={searchText}
              onChange={(e) => onSearchTextChange(e.target.value)}
              onBlur={() => handleInputConfirm()}
              onPressEnter={() => handleInputConfirm()}
            />

            <div style={{ padding: 10 }}>
              {keywords.map((keyword) => {
                return (
                  <Tag
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      onRemoveKeyword(keyword);
                    }}
                  >
                    {keyword}
                  </Tag>
                );
              })}
            </div>
          </div>

          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {contractsLength !== 0 && (
                <>
                  <div>
                    {downloads.visible && (
                      <DownloadCsv t={t} downloads={downloads} />
                    )}
                    <Icon type="file-search" /> {contractsLength}{" "}
                    {t("contractFound")}
                  </div>
                </>
              )}
              <a
                href="https://teams.microsoft.com/l/channel/19%3aNlBntUHozxNc2urQuKyBsNXxrvv3F49feJflZluDDQQ1%40thread.tacv2/General?groupId=cec04228-94c0-44c6-b71b-e881e5e8584f&tenantId=101ce67d-13f2-447a-bb65-0989b89dfdb4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tooltip title={t("liveChatTp")}>
                  <Button
                    icon={"wechat"}
                    type="primary"
                    style={{
                      backgroundColor: "#E30536",
                      color: "white",
                      borderColor: "white",
                      boxShadow: "none",
                      marginLeft: "10px",
                    }}
                  >
                    {t("liveChat")}
                  </Button>
                </Tooltip>
              </a>
              <Dropdown overlay={<LanguageMenu t={t} />}>
                <Icon
                  type="global"
                  style={{ fontSize: 20, marginLeft: 20 }}
                  title={t(i18n.language)}
                />
              </Dropdown>
              <a
                href="https://kochprod.service-now.com/compass?id=kb_article&table=kb_knowledge&sys_kb_id=9a3e1b081b38ed104d2dbbb1cd4bcba4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Tooltip title={t("helpLink")}>
                  <Icon
                    type="bulb"
                    style={{ fontSize: 20, marginLeft: 20, color: "white" }}
                  />
                </Tooltip>
              </a>
            </div>
          </>
        </div>
      )}
    </Header>
  );
};

export default withTranslation()(PageHeader);
