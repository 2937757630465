import React from "react";
import {
  Spin,
  Layout,
  Icon,
  Button,
  Tooltip,
  notification,
  message,
  Popconfirm,
} from "antd";

import "antd/dist/antd.css";
import ContractsTable from "./ContractsTable";
import { createTicket } from "../utils/servicenow-helper";
import { withTranslation } from "react-i18next";

const { Content, Footer } = Layout;

const getContractsView = (
  inResetState,
  t,
  loading,
  contracts,
  selectedRows,
  onContractSelect,
  onSupplierClick
) => {
  // No filter selected.
  if (inResetState === true)
    return (
      <Content className="zero-loading-state">
        <div>
          <Icon type="info-circle" /> {t("setKeyword")}
        </div>
      </Content>
    );
  // If filter has been applied and the data is loading.
  if (loading === true)
    return (
      <Content className="zero-loading-state">
        <Spin />
      </Content>
    );
  // Data loaded with no results. Add zero state.
  if (contracts.length === 0)
    return (
      <Content className="zero-loading-state">
        <div>
          <Icon type="exclamation" /> {t("error")}
        </div>
      </Content>
    );
  // Display data.
  return (
    <ContractsTable
      contracts={contracts}
      selectedRows={selectedRows}
      onContractSelect={onContractSelect}
      onSupplierClick={onSupplierClick}
    />
  );
};

const selectedContractsIds = (selectedRows, contracts) => {
  const selectedContracts = contracts.filter((contract) =>
    selectedRows.includes(contract.contract_id)
  );
  let details = "";
  selectedContracts.forEach((contract, index) => {
    let contract_details = "";
    Object.keys(contract).forEach((key) => {
      if (key === "contract_id")
        contract_details += "Contract ID: " + contract[key] + "\n";
      else if (key === "description")
        contract_details += "Description: " + contract[key] + "\n";
      else if (key === "supplier_name")
        contract_details += "Supplier name: " + contract[key] + "\n";
    });

    details += "\n\n" + (index + 1) + ".\n" + contract_details;
  });
  return details;
};

const requestComparison = async (selectedRows, contracts, t) => {
  let body =
    "Hello Legal Team,\nThere is a request submitted to compare the contracts. Please refer to the contract details below.";
  body += selectedContractsIds(selectedRows, contracts);

  try {
    const hide = message.loading(t("contactingServiceNow"), 0);
    setTimeout(hide, 1500);
    const { request_id, request_number } = await createTicket(body);
    successNotification(request_id, request_number, t);
  } catch (error) {
    errorNotification(t);
  }
};

const requestContractCopies = async (selectedRows, contracts, t) => {
  let body =
    "Hello Legal Team,\nThere is a request submitted to get a copy of below contract details from Ariba.";
  body += selectedContractsIds(selectedRows, contracts);

  try {
    const hide = message.loading(t("contactingServiceNow"), 0);
    setTimeout(hide, 1500);
    const { request_id, request_number } = await createTicket(body);
    successNotification(request_id, request_number, t);
  } catch (error) {
    errorNotification(t);
  }
};

const successNotification = (request_id, request_number, t) => {
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_SERVICE_NOW_URL}/compass?id=sc_request&table=sc_request&sys_id=${request_id}`,
          "_blank"
        )
      }
    >
      {t("trackStatusBtnTxt")}
    </Button>
  );
  notification.open({
    message: t("serviceNowReqRaised"),
    description: `${request_number}:` + t("serviceNowSuccessDesc"),
    btn,
  });
};

const errorNotification = (t) => {
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_SERVICE_NOW_URL}/compass?id=sc_cat_item&sys_id=9b63a1e51becf0503d86a681604bcb75`,
          "_blank"
        )
      }
    >
      {t("raiseReqBtn")}
    </Button>
  );
  notification.open({
    message: t("failedToRaiseRequest"),
    description: t("raiseReqDesc"),
    btn,
  });
};

const Contracts = ({
  margin,
  inResetState,
  loading,
  contracts,
  selectedRows,
  onContractSelect,
  onSupplierClick,
  page,
  onPageChange,
  t,
}) => {
  return (
    <Content style={{ marginLeft: margin }}>
      <Layout>
        {getContractsView(
          inResetState,
          t,
          loading,
          contracts,
          selectedRows,
          onContractSelect,
          onSupplierClick,
          page,
          onPageChange
        )}
        <Footer
          className="footer"
          style={{ width: window.innerWidth - margin }}
        >
          <Popconfirm
            title={t("requestComparisonDialog")}
            disabled={selectedRows.length < 2}
            onConfirm={() => requestComparison(selectedRows, contracts, t)}
            okText="Yes"
            cancelText="No"
          >
            <div>
              <Button
                size="default"
                style={{ marginRight: "15px" }}
                onClick={() => {
                  window.open(
                    "https://app.powerbi.com/groups/ef5d9764-3a15-4648-bad5-eabfde4250a0/reports/30234f67-578a-459c-9376-b920dbe5892f/ReportSection389d0c360b38865e8c1c?ctid=101ce67d-13f2-447a-bb65-0989b89dfdb4",
                    "_blank"
                  );
                }}
              >
                {t("allAboutContract")}
              </Button>

              <Tooltip
                title={selectedRows.length < 2 ? t("compareContractsTp") : ""}
              >
                <Button
                  type="primary"
                  disabled={selectedRows.length < 2}
                  size="default"
                >
                  {t("compareContracts")}
                </Button>
              </Tooltip>
            </div>
          </Popconfirm>
          <div>
            <Popconfirm
              title={t("requestCopiesDialog")}
              disabled={selectedRows.length < 1}
              onConfirm={() =>
                requestContractCopies(selectedRows, contracts, t)
              }
              okText="Yes"
              cancelText="No"
            >
              <Tooltip
                title={selectedRows.length < 1 ? t("requestCopyTp") : ""}
              >
                <Button
                  style={{ marginRight: 10 }}
                  type="primary"
                  disabled={selectedRows.length < 1}
                  size="default"
                >
                  {t("requestCopy")}
                </Button>
              </Tooltip>
            </Popconfirm>

            <Button
              style={{ marginRight: 10 }}
              size="default"
              onClick={() =>
                window.open(
                  "https://kochprod.service-now.com/compass?id=sc_cat_item&sys_id=bdd7ea151b27a0d0defecbf3604bcb7d%20",
                  "_blank"
                )
              }
            >
              {t("initNDA")}
            </Button>
            <Button
              size="default"
              onClick={() =>
                window.open(
                  "https://kochprod.service-now.com/compass?id=sc_cat_item&sys_id=9b63a1e51becf0503d86a681604bcb75",
                  "_blank"
                )
              }
            >
              {t("askQues")}
            </Button>
          </div>
        </Footer>
      </Layout>
    </Content>
  );
};

export default withTranslation()(Contracts);

// mailto:nda@molex.com?Subject=Compare%20contracts1.contract_id%20:%20LOPLW0085description%20:%20HONESTY%20AND%20INCORRUPTNESS%20AGREEMENT(HAIA)%20LIAONING%20CROWNTECH%20PHOTONICS%20CO%20LTDsupplier_name%20:%202.contract_id%20:%20LOPLW0788description%20:%20NON%20DISCLOSURE%20AGREEMENT(NDA)%20FOR%20LIAONING%20CROWNTECH%20PHOTONICS%20CO%20LTDsupplier_name%20:
